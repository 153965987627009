CreateDownloadListenerhandler();

function CreateDownloadListenerhandler() {
  var downloadBtn = document.getElementById("DownloadButton");
  if (downloadBtn) {
    downloadBtn.addEventListener("click", () => {
      // Ok let's call the Azure logic app to send an email that a download happened
      console.log("calling API to log download");
      fetch(
        "https://prod-24.australiasoutheast.logic.azure.com:443/workflows/cfa18f55a9a5415eb6ee324fe18bb06d/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=AE-zwgK4_Y-GfNB-jUElhbDCBl-uApiDNWXPd_Nsx20",
        {
          method: "POST",
        }
      );
    });
  } else {
    console.log("Cannot find download button");
  }
}
